<template>
  <div>
    <v-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ title }}
          </strong>
          <br />
          <i>
            {{ $t("LODGING.SUB_TITLE") }}
          </i>
        </h5>
      </b-col>

      <!-- APARTMENT TYPE -->
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-select
          v-model="info.apartmentTypeId"
          :items="ApartmentTypes"
          :label="$t('LODGING.TYPE')"
          item-text="name"
          item-value="apartmentTypeId"
          dense
          outlined
        />
      </b-col>

      <!-- USEFUL SURFACE -->
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-text-field
          v-model="info.usefulSurface"
          type="number"
          :label="$t('LODGING.SURFACE')"
          :rules="validations.noNegative"
          outlined
          dense
        />
      </b-col>

      <!-- MESURE TYPE -->
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-select
          v-model="info.mesureTypeId"
          :items="optionMesure"
          :label="$t('LODGING.MESURE')"
          item-text="text"
          item-value="value"
          dense
          outlined
        />
      </b-col>

      <!-- LIVING ROOM -->
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-checkbox
          v-model="info.livingRoom"
          :label="$t('LODGING.LIVING_ROOM')"
        />
      </b-col>

      <!-- KITCHEN -->
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-checkbox v-model="info.kitchen" :label="$t('LODGING.KITCHEN')" />
      </b-col>

      <!-- LOUNGE KITCHEN -->
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-checkbox
          v-model="info.loungeKitchen"
          :label="$t('LODGING.LOUNGE_KITCHEN')"
        />
      </b-col>

      <!-- SHOWER -->
      <b-col lg="4" md="4" sm="4" cols="12" class="input-check">
        <v-checkbox v-model="info.shower" :label="$t('LODGING.SHOWER')" />
      </b-col>

      <!-- BATHTUB -->
      <b-col lg="4" md="4" sm="4" cols="12" class="input-check">
        <v-checkbox v-model="info.bathtub" :label="$t('LODGING.BATHTUB')" />
      </b-col>

      <!-- TITLE -->
      <b-col lg="4" md="4" sm="4" cols="12" />

      <!-- bathroom with shower & bath -->
      <b-col lg="3" md="3" sm="8" cols="8">
        <h5 class="mt-2 font-weight-normal">
          {{ $t("LODGING.BATHROOMS1") }}
        </h5>
      </b-col>
      <b-col lg="2" md="2" sm="4" cols="4">
        <v-select
          v-model="info.commonBathrooms1"
          :items="options5"
          :label="$t('LODGING.QUANTITY')"
          item-text="text"
          item-value="value"
          dense
          outlined
        />
      </b-col>
      <b-col lg="7" md="7" sm="0" cols="0" />

      <!-- bathroom with shower-->
      <b-col lg="3" md="3" sm="8" cols="8">
        <h5 class="mt-2 font-weight-normal">
          {{ $t("LODGING.BATHROOMS2") }}
        </h5>
      </b-col>
      <b-col lg="2" md="2" sm="4" cols="4">
        <v-select
          v-model="info.commonBathrooms2"
          :items="options5"
          :label="$t('LODGING.QUANTITY')"
          item-text="text"
          item-value="value"
          dense
          outlined
        />
      </b-col>
      <b-col lg="7" md="7" sm="0" cols="0" />

      <!-- bathroom with bath -->
      <b-col lg="3" md="3" sm="8" cols="8">
        <h5 class="mt-2 font-weight-normal">
          {{ $t("LODGING.BATHROOMS3") }}
        </h5>
      </b-col>
      <b-col lg="2" md="2" sm="4" cols="4">
        <v-select
          v-model="info.commonBathrooms3"
          :items="options5"
          :label="$t('LODGING.QUANTITY')"
          item-text="text"
          item-value="value"
          dense
          outlined
        />
      </b-col>
      <b-col lg="7" md="7" sm="0" cols="0" />

      <!-- Bedrooms  -->
      <b-col lg="3" md="3" sm="8" cols="8">
        <h5 class="mt-2 font-weight-normal">
          {{ $t("LODGING.BEDROOMS") }}
        </h5>
      </b-col>
      <b-col lg="2" md="2" sm="4" cols="4">
        <v-select
          v-model="info.roomsQty"
          :items="options10"
          :label="$t('LODGING.QUANTITY')"
          item-text="text"
          item-value="value"
          dense
          outlined
        />
      </b-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import { listOptions5, listOptions10, listMesure } from "./../util/list";

export default {
  props: {
    title: String,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        apartmentTypeId: null,
        usefulSurface: null,
        mesureTypeId: null,
        livingRoom: false,
        kitchen: false,
        loungeKitchen: false,
        shower: false,
        bathtub: false,
        commonBathrooms1: 0,
        commonBathrooms2: 0,
        commonBathrooms3: 0,
        roomsQty: null,
      },
      // OPTIONS
      options5: listOptions5,
      options10: listOptions10,
      optionMesure: listMesure,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.edit) {
        this.form.apartmentTypeId = this.accommodation.apartmentTypeId;
        this.form.usefulSurface = this.accommodation.usefulSurface;
        this.form.mesureTypeId = this.accommodation.mesureTypeId;
        this.form.livingRoom = this.accommodation.livingRoom;
        this.form.kitchen = this.accommodation.kitchen;
        this.form.loungeKitchen = this.accommodation.loungeKitchen;
        this.form.shower = this.accommodation.shower;
        this.form.bathtub = this.accommodation.bathtub;
        this.form.commonBathrooms1 = this.accommodation.commonBathrooms1;
        this.form.commonBathrooms2 = this.accommodation.commonBathrooms2;
        this.form.commonBathrooms3 = this.accommodation.commonBathrooms3;
        this.form.roomsQty = this.accommodation.roomsQty;
      }
    },
  },
  computed: {
    ...mapGetters(["accommodation", "ApartmentTypes"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:apartment-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
