|<template>
  <div>
    <v-row gutters="2" justify="center">
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("LODGING.ROOM_LIST") }}
          </strong>
        </h5>
      </b-col>
      <v-col cols="12" md="12">
        <RoomTable
          :isShowElevation="false"
          :isReveivedData="true"
          :listRoomData="listRooms"
        ></RoomTable>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RoomTable from "@/view/content/components/products_addons/room/Table";
export default {
  components: {
    RoomTable,
  },
  data() {
    return {
      isShowDialogDelete: false,
      headers: [
        {
          text: this.$t("PRODUCTS_ADDONS.NAME"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: `${this.$t("PRODUCTS_ADDONS.ROOM")} ${this.$t(
            "PRODUCTS_ADDONS.CATEGORY"
          )}`,
          align: "left",
          sortable: true,
          value: "category.codeName",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.QUANTITY"),
          align: "center",
          sortable: true,
          value: "qtyStudent",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.LAST_UPDATED"),
          align: "left",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.ACTIONS"),
          align: "center",
          sortable: false,
          value: "ACTIONS",
        },
      ],
      dataRoom: {},
    };
  },
  methods: {
    ...mapActions(["deleteRoom", "getRooms"]),
    deleteRecord(data) {
      this.dataRoom = data;
      this.isShowDialogDelete = true;
    },
  },
  mounted() {
    this.getRooms();
  },
  computed: {
    ...mapGetters(["accommodation", "campus", "rooms"]),
    listRooms() {
      const auxRooms = this.rooms.filter(
        room => room.accommodationId === this.accommodation.accommodationId
      );
      return auxRooms;
    },
  },
  watch: {
    $route: "getRooms",
    accommodation: "getRooms",
  },
};
</script>
