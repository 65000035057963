<template>
  <b-row>
    <!-- TITLE -->
    <b-col lg="12" md="12" sm="12" cols="12">
      <h5>
        <strong>
          {{ $t("LODGING.TITLE6") }}
        </strong>
        <br />
        <i>
          {{ $t("LODGING.TITLE23") }}
        </i>
      </h5>
    </b-col>

    <div
      v-for="(transport, index) in form.accommodation_transports"
      :key="index"
    >
      <v-row class="mb-0 pb-0">
        <!-- Transport Type -->
        <b-col cols="12" lg="4" md="4" sm="4">
          <v-select
            v-model="transport.transportTypeId"
            :items="TransportTypes"
            :label="$t('LODGING.TYPE')"
            item-text="name"
            item-value="transportTypeId"
            dense
            outlined
          />
        </b-col>

        <!-- Distance -->
        <b-col cols="12" lg="3" md="3" sm="3">
          <v-text-field
            v-model="transport.distance"
            :label="$t('LODGING.DISTANCE')"
            :rules="validations.noNegative"
            type="number"
            outlined
            dense
          />
        </b-col>

        <!-- Units -->
        <b-col cols="12" lg="3" md="3" sm="3">
          <v-select
            v-model="transport.distanceTypeId"
            :items="unitOptions"
            :label="$t('LODGING.UNIT')"
            item-text="text"
            item-value="value"
            dense
            outlined
          />
        </b-col>

        <!-- DELETE TRANSPORT -->
        <b-col cols="12" md="1">
          <a
            class="btn btn-icon btn-light btn-hover-danger"
            @click="removeTransport(index)"
          >
            <span class="svg-icon svg-icon-md svg-icon-danger">
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </span>
          </a>
        </b-col>
      </v-row>
    </div>

    <!-- ADD -->
    <b-col lg="12" md="12" sm="12" cols="12">
      <v-btn
        depressed
        color="primary"
        @click="addTransport"
        v-if="
          form.accommodation_transports &&
            form.accommodation_transports.length < 3
        "
      >
        {{ $t("GENERAL.ADD") }}
      </v-btn>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import { unitList2 } from "./../util/list";

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        accommodation_transports: [],
      },
      //OPTIONS SELECT
      unitOptions: unitList2,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * set accommodation_transports with new data
     * @method addTransport
     * @returns 'accommodation_transports' object whit new data
     */
    addTransport() {
      this.form.accommodation_transports.push({
        transportTypeId: null,
        distance: null,
        distanceTypeId: 1,
      });
    },

    /**
     * set accommodation_transports with new data
     * @method removeTransport
     * @returns 'accommodation_transports' object whit new data
     */
    removeTransport(index) {
      this.form.accommodation_transports.splice(index, 1);
    },
    fetchData() {
      if (this.edit) {
        this.form.accommodation_transports = this.accommodation.accommodation_transports;
      }
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["accommodation", "TransportTypes"]),
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:transport-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
