<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.minDuration"
          :label="$t('LODGING.DURATION.MIN_DURATION')"
          :rules="validations.required"
          type="number"
          required
          outlined
          dense
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span class="d-block">
                {{ $t("LODGING.MIN_DURATION_INFO") }}
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="form.minDurationTypeId"
          :rules="validations.required"
          :items="durationType"
          :label="$t('LODGING.DURATION.MIN_DURATION_TYPE')"
          item-text="name"
          item-value="id"
          required
          dense
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.minSellingDuration"
          :label="$t('LODGING.DURATION.MIN_SELLING_DURATION')"
          :rules="validations.required"
          type="number"
          required
          outlined
          dense
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span class="d-block">
                {{ $t("LODGING.MIN_DURATION_SOLD_INFO") }}
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="form.minSellingDurationTypeId"
          :rules="validations.required"
          :items="durationType"
          :label="$t('LODGING.DURATION.MIN_SELLING_DURATION_TYPE')"
          item-text="name"
          item-value="id"
          required
          dense
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Rules from "@/core/services/utils/validations";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        minDuration: null,
        minDurationTypeId: null,
        minSellingDuration: null,
        minSellingDurationTypeId: null,
      },
      durationType: [
        {
          id: 2,
          name: this.$i18n.t("FEE.WEEK"),
        },
        {
          id: 3,
          name: this.$i18n.t("FEE.MONTH"),
        },
      ],
      validations: {
        ...Rules,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.form.minDuration = this.accommodation.minDuration;
      this.form.minDurationTypeId = this.accommodation.minDurationTypeId;
      this.form.minSellingDuration = this.accommodation.minSellingDuration;
      this.form.minSellingDurationTypeId = this.accommodation.minSellingDurationTypeId;
    },
  },
  computed: {
    ...mapGetters(["accommodation"]),
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:duration-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
