<template>
  <div class="accommodation-settings">
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="save"
      :save_loading="save_loading"
      :btnDuplicate="false"
      :btnDelete="true"
      :delete_loading="delete_loading"
      :textDelete="$t('GENERAL.DELETE')"
      v-on:delete="isShowDialogDelete = true"
      :btnSaveNew="true"
      :textSaveNew="$t('LODGING.NEW_ROOM')"
      v-on:save-new="createRoom"
    />
    <!-- FORM LODGING -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- BASIC INFORMATION -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <BasicInformation
            :basic-info.sync="basicInformation"
            :edit="true"
            v-on:change="updatedFacilities"
          />
        </div>
      </div>

      <!-- STARTDATES -->
      <div
        class="card card-custom card-stretch gutter-b w-100"
        v-if="company.companyTypeId === COMPANY_TYPE_COURSE"
      >
        <div class="card-body">
          <v-row class="mt-0 mb-0 pt-0 pb-0">
            <v-col cols="12">
              <h5>{{ $t("PRODUCTS_ADDONS.TEMPORAL_SETTINGS") }}</h5>
              <Duration :duration-info.sync="durationInformation"></Duration>
              <v-expand-transition>
                <div>
                  <v-radio-group v-model="startDateType" row>
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.SCHOOL_CALENDAR')"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.CUSTOM_CALENDAR')"
                      :value="1"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-row v-show="startDateType === 1">
                      <v-col cols="12">
                        <StartDateTemplate
                          ref="startDateTemplate"
                          :startDateTemplate="startDateTemplate"
                          v-on:save_start_date_template="
                            getDataStartDate($event)
                          "
                        ></StartDateTemplate>
                      </v-col>
                    </v-row>
                  </v-expand-transition>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </div>
      </div>

      <!-- FINANCIAL DATA -->
      <div
        class="card card-custom card-stretch gutter-b w-100"
        v-if="showLodgingInfo"
      >
        <div class="card-body">
          <FinancialData
            :financial-info.sync="financialInformation"
            :title="$t('LODGING.TITLE4')"
            :edit="true"
            :showName="true"
            :ladging="true"
          />
        </div>
      </div>

      <!-- DESCRIPTION DATA -->
      <b-row>
        <!-- Description -->
        <b-col lg="8" md="8" sm="12" cols="12" class="mb-5">
          <div class="card card-custom card-stretch gutter-b w-100">
            <div class="card-body">
              <DescriptionData
                :description-info.sync="descriptionInformation"
                :edit="true"
              />
            </div>
          </div>
        </b-col>

        <!-- Translation -->
        <b-col lg="4" md="4" sm="12" cols="12" class="mb-5">
          <Translation
            ref="translationComponent"
            :fields="dataTranslation"
            model="lodging"
            :skeleton="7"
            maxHeight="420px"
          />
        </b-col>
      </b-row>

      <!-- TRANSPORT -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <PublicTransport
            :transport-info.sync="transportInformation"
            :edit="true"
          />
        </div>
      </div>

      <!-- GENERAL PHOTOS OF LADGING -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <Gallery
            :title="$t('LODGING.TITLE7')"
            :showInfo="true"
            :infoText1="$t('LODGING.INFO1')"
            :files="dataGallery"
            @data="filesGallery = $event"
            ref="actionsGallery"
            entity="lodging-general"
          />
        </div>
      </div>

      <!-- LODGING INFORMATION -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <LodgingInformation
            :lodging-info.sync="lodgingInformation"
            ref="methodsInformation"
            :edit="true"
          />
        </div>
      </div>

      <!-- HOMESTAY INFORMATION -->
      <div
        class="card card-custom card-stretch gutter-b w-100"
        v-if="type === 1"
      >
        <div class="card-body">
          <ApartmentInformation
            :apartment-info.sync="apartmentInfo"
            :title="$t('LODGING.TITLE12')"
            :edit="true"
          />

          <PeopleFamily
            :people-info.sync="peopleInformation"
            :edit="true"
            :isShowPeopleLiving="
              basicInformation.accommodationTypeId === LODGING_TYPE_HOMESTAY ||
                basicInformation.accommodationTypeId ===
                  LODGING_TYPE_SHARED_APARTMENT
            "
          />
          <Pets :pet-info.sync="petInformation" :edit="true" />

          <!-- FAMILY GALLERY -->
          <Gallery
            :title="$t('LODGING.TITLE17')"
            :showInfo="true"
            id="_family"
            :infoText1="$t('LODGING.INFO1')"
            :files="dataGalleryFamily"
            @data="filesGalleryFamily = $event"
            ref="actionsGallery"
            entity="lodging-family"
          />

          <!-- GUEST -->
          <h5>
            <strong>
              {{ $t("LODGING.TITLE24") }}
            </strong>
          </h5>

          <b-row>
            <b-col lg="1" md="1" sm="3" cols="3">
              <strong>
                <h5 class="mt-2 font-weight-normal">
                  {{ $t("LODGING.TITLE20") }}
                </h5>
              </strong>
            </b-col>
            <b-col lg="2" md="2" sm="4" cols="4">
              <v-select
                v-model="guestsQty"
                :items="Options11"
                :label="$t('LODGING.QUANTITY')"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
            </b-col>
          </b-row>

          <!-- SOCIAL INFORMATION -->
          <v-row no-gutters>
            <b-col lg="12" md="12" sm="12" cols="12">
              <h5>
                <strong>
                  {{ $t("LODGING.TITLE19") }}
                </strong>
              </h5>
            </b-col>
            <b-col lg="12" md="12" sm="12" cols="12">
              <v-radio-group v-model="socialLive" label="" column>
                <v-radio :label="$t('LODGING.SOCIAL.SOCIAL_OPT1')" :value="1" />
                <v-radio :label="$t('LODGING.SOCIAL.SOCIAL_OPT2')" :value="2" />
              </v-radio-group>
            </b-col>
          </v-row>
        </div>
      </div>

      <!-- APARTMENT INFORMATION -->
      <div
        class="card card-custom card-stretch gutter-b w-100"
        v-if="type === 3 || type === 4"
      >
        <div class="card-body">
          <ApartmentInformation
            :apartment-info.sync="apartmentInfo"
            :title="$t('LODGING.TITLE13')"
            :edit="true"
          />
        </div>
      </div>

      <!-- ROOMS -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <Rooms />
        </div>
      </div>

      <!-- COURSES -->
      <v-card class="mb-2">
        <v-card-title>{{ $t("LODGING.TITLE25") }}</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <ProductsTreeView
                :input.sync="coursesData"
                :selection="coursesData"
                :data="courses"
                :name="$t('LODGING.COURSES')"
                keyId="productId"
                keyName="name"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- MODAL DELETE -->
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deleteLodging">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_ACCOMMODATION,
  UPDATE_ACCOMMODATION,
  DELETE_ACCOMMODATION,
} from "@/core/services/store/general/accommodation.module";
import BasicInformation from "./components/BasicInformation";
import FinancialData from "@/view/content/components/FinancialData";
import PublicTransport from "./components/PublicTransport";
import LodgingInformation from "./components/LodgingInformation";
import ApartmentInformation from "./components/ApartmentInformation";
import PeopleFamily from "./components/PeopleFamily";
import Pets from "./components/Pets";
import Gallery from "@/view/content/components/images/Gallery";
import Buttons from "@/view/content/components/Buttons";
import { listOptions11 } from "./util/list";
import Translation from "@/view/content/components/translation/Index";
import DescriptionData from "./components/DescriptionData";
import Rooms from "./components/Rooms";
import StartDateTemplate from "@/view/content/components/forms/StartDateTemplate";
import Duration from "@/view/pages/lodging/components/Duration";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";

const ZONE_SCHOOL = 1;

export default {
  components: {
    BasicInformation,
    FinancialData,
    PublicTransport,
    Gallery,
    LodgingInformation,
    ApartmentInformation,
    PeopleFamily,
    Pets,
    Buttons,
    Translation,
    DescriptionData,
    Rooms,
    StartDateTemplate,
    Duration,
    ProductsTreeView,
  },
  data() {
    return {
      COMPANY_TYPE_COURSE: 1,
      LODGING_TYPE_HOMESTAY: 1,
      LODGING_TYPE_SHARED_APARTMENT: 4,
      startDateType: 0,
      startDateTemplate: {},
      startDateTemplate_modified: {},
      valid: true,
      isShowDialogDelete: false,
      save_loading: false,
      duplicate_loading: false,
      basicInformation: null,
      durationInformation: null,
      financialInformation: null,
      descriptionInformation: null,
      transportInformation: null,
      dataGallery: [],
      filesGallery: [],
      lodgingInformation: null,
      apartmentInfo: null,
      peopleInformation: null,
      petInformation: null,
      dataGalleryFamily: [],
      filesGalleryFamily: [],
      guestsQty: null,
      socialLive: null,
      Options11: listOptions11,
      dataTranslation: [
        {
          title: this.$i18n.t("LODGING.ACCOMMODATION_DESCRIPTION"),
          type: "description",
        },
        {
          title: this.$i18n.t("LODGING.ENVIROMENT_DESCRIPTION"),
          type: "description2",
        },
      ],
      coursesData: [],
    };
  },
  methods: {
    ...mapActions(["getCourses", "getAccommodation"]),
    updatedFacilities(category) {
      this.$refs.methodsInformation.updatedAmenity(category);
    },

    /**
     * return new start date template
     * @method createRoom
     */
    getDataStartDate(startDateTemplate) {
      this.startDateTemplate_modified = startDateTemplate;
    },
    /**
     * return new room
     * @method createRoom
     */
    createRoom() {
      this.$router.push("/products/room/new");
    },

    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/lodging");
    },

    /**
     * Save new Information
     * @method save
     */
    async save() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        if (this.company.companyTypeId === this.COMPANY_TYPE_COURSE) {
          let check_validation_start_date = await this.$refs.startDateTemplate.validate();
          if (!check_validation_start_date) {
            this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
              variant: "danger",
              solid: true,
            });
            return false;
          }
        }

        this.save_loading = true;

        const data = {
          accommodationId: this.$route.params.id,
          campusId: this.accommodation.campusId,
          ...this.basicInformation,
          ...this.durationInformation,
          ...this.financialInformation,
          ...this.descriptionInformation,
          ...this.transportInformation,
          ...this.lodgingInformation,
          ...this.apartmentInfo,
          ...this.peopleInformation,
          ...this.petInformation,
          coursesData: this.coursesData,
          guestsQty: this.guestsQty,
          socialLive: this.socialLive,
        };

        data.minDistance =
          data.zoneId === ZONE_SCHOOL
            ? data.minDistanceSchool
            : data.minDistanceCity;
        data.maxDistance =
          data.zoneId === ZONE_SCHOOL
            ? data.maxDistanceSchool
            : data.maxDistanceCity;
        data.distanceTypeId =
          data.zoneId === ZONE_SCHOOL
            ? data.distanceTypeIdSchool
            : data.distanceTypeIdCity;

        // ADD START DATE TEMPLATE
        if (this.company.companyTypeId === this.COMPANY_TYPE_COURSE) {
          data.startDateTemplateSave = false;
          if (this.startDateType === 1) {
            data.startDateTemplateSave = true;
            data.startDateTemplate = this.startDateTemplate_modified;
          }
        }

        // GET PHOTO IDS
        const id_photos = [];
        if (this.filesGallery) {
          this.filesGallery.forEach(photo => {
            id_photos.push(photo.fileId);
          });

          data.photos = id_photos.join();
        } else {
          data.photos = "";
        }

        // GET PHOTO IDS
        const family_photos = [];
        if (this.filesGalleryFamily) {
          this.filesGalleryFamily.forEach(photo => {
            family_photos.push(photo.fileId);
          });

          data.family_photos = family_photos.join();
        } else {
          data.family_photos = "";
        }

        // Delete values NUll
        const cls_facilities = data.facilities_facilities.filter(
          amenity => amenity !== null
        );

        data.facilities_facilities = cls_facilities;

        const cls_facilities_security = data.facilities_security.filter(
          amenity => amenity !== null
        );

        data.facilities_security = cls_facilities_security;

        // SET cancellationPolicy
        const { companyTypeId } = this.company;
        if (companyTypeId !== 3) {
          data.cancellationPolicy = 0;
        }

        // Set data descriptions
        if (this.descriptionInformation) {
          const {
            accommodation_description,
            enviroment_description,
          } = this.descriptionInformation;
          const dataJson = [accommodation_description, enviroment_description];
          if (accommodation_description || enviroment_description) {
            await this.$refs.translationComponent.getTranslations(
              this.accommodation.accommodationId,
              dataJson
            );
          }
        }

        this.$store
          .dispatch(UPDATE_ACCOMMODATION, data)
          .then(accommodation => {
            //If it has been saved the start date template, we must refresh this template in the component.
            if (accommodation.product_start_date_template)
              this.startDateTemplate =
                accommodation.product_start_date_template;

            this.save_loading = false;

            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          })
          .catch(() => {
            this.save_loading = false;
          });
      }
    },

    /**
     * Delete lodging seleted
     * @method deleteLodging
     */
    deleteLodging() {
      this.isShowDialogDelete = false;
      const { accommodationId } = this.accommodation;
      this.$store.dispatch(DELETE_ACCOMMODATION, accommodationId).then(() => {
        this.$router.push("/lodging");
      });
    },
    async fetchData() {
      if (
        this.accommodation &&
        this.accommodation.accommodationId !== this.$route.params.id
      ) {
        this.$store
          .dispatch(GET_ACCOMMODATION, this.$route.params.id)
          .then(data => {
            this.prepareData(data);
          });
      } else {
        await this.prepareData({ accommodation: this.accommodation });
      }
    },
    async prepareData(data) {
      if (data.accommodation.productStartDateTemplateId === 0) {
        this.startDateType = 0;
      } else {
        this.startDateType = 1;
        this.startDateTemplate = data.accommodation.product_start_date_template;
      }
      this.dataGallery = data.accommodation.photos;
      this.dataGalleryFamily = data.accommodation.family_photos;
      this.coursesData = data.accommodation?.product_courses?.map(item => ({
        id: item.productId,
      }));
      this.socialLive = data.accommodation.socialLive;
      this.guestsQty = data.accommodation.guestsQty;

      // SET TRANSLATE
      const dataJson = [
        data.accommodation.accommodation_description,
        data.accommodation.enviroment_description,
      ];

      if (
        data.accommodation.accommodation_description ||
        data.accommodation.enviroment_description
      ) {
        await this.$refs.translationComponent.getTranslations(
          data.accommodation.accommodationId,
          dataJson
        );
      }
      await this.getCourses();
    },
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("LODGING.PAGE"),
        route: "/lodging",
      },
      { title: this.$t("GENERAL.EDIT") },
    ]);
    await this.fetchData();
  },
  computed: {
    ...mapGetters(["accommodation", "company", "campus", "courses"]),
    type() {
      if (this.basicInformation) {
        return this.basicInformation.accommodationTypeId;
      }
      return 0;
    },
    showLodgingInfo() {
      const { companyTypeId } = this.company;
      if (companyTypeId === 3) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route: "fetchData",
  },
};
</script>
