<template>
  <b-row>
    <!-- TITLE -->
    <b-col lg="12" md="12" sm="12" cols="12">
      <h5>
        <strong>
          {{ title }}
        </strong>
      </h5>
    </b-col>

    <!-- COMPANY NAME -->
    <b-col lg="6" md="6" sm="12" cols="12" v-if="showName">
      <v-text-field
        v-model="info.companyName"
        :label="$t('FINANCIAL.COMPANY_NAME')"
        outlined
        dense
      />
    </b-col>

    <!-- IBAN -->
    <b-col lg="6" md="6" sm="6" cols="12">
      <v-text-field
        v-model="info.iban"
        :label="$t('FINANCIAL.IBAN')"
        :rules="[validations.maxValue(info.iban, 30)]"
        counter="30"
        outlined
        dense
      />
    </b-col>

    <!-- BIC -->
    <b-col lg="6" md="6" sm="6" cols="12">
      <v-text-field
        v-model="info.bic"
        :label="$t('FINANCIAL.BIC')"
        :rules="[validations.maxValue(info.bic, 11)]"
        counter="11"
        outlined
        dense
      />
    </b-col>

    <!-- ABA -->
    <b-col lg="6" md="6" sm="6" cols="12">
      <v-text-field
        v-model="info.aba"
        :label="$t('FINANCIAL.ABA')"
        :rules="[validations.maxValue(info.aba, 9)]"
        counter="9"
        outlined
        dense
      />
    </b-col>

    <!-- TITLE 2 -->
    <b-col lg="12" md="12" sm="12" cols="12" v-if="Policy">
      <h5>
        {{ $t("LODGING.TITLE9") }}
      </h5>
    </b-col>

    <!-- POLICY CANCELLATION -->
    <b-col lg="6" md="6" sm="6" cols="12" v-if="Policy">
      <v-slider
        v-model="info.cancellationPolicy"
        append-icon="add_circle"
        prepend-icon="remove_circle"
        min="0"
        max="60"
        thumb-label="always"
        :thumb-size="24"
      />
      <em>
        {{ $t("SETTINGS.USER.BUSSINESS.POLICY_CANCELLATION") }}
      </em>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";

export default {
  props: {
    title: String,
    showName: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    ladging: {
      type: Boolean,
      default: false,
    },
    campus: {
      type: Boolean,
      default: false,
    },
    Policy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        companyName: null,
        iban: null,
        bic: null,
        aba: null,
        cancellationPolicy: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.edit) {
        if (this.campus) {
          // GET INFO CAMPUS OR COMPANY
          this.form.iban = this.campus.iban;
          this.form.bic = this.campus.bic;
          this.form.aba = this.campus.aba;
        } else if (this.ladging) {
          // GET INFO LADGING OR COMPANY
          this.form.iban = this.accommodation.iban;
          this.form.bic = this.accommodation.bic;
          this.form.aba = this.accommodation.aba;
          this.form.companyName = this.accommodation.companyName;
          this.form.cancellationPolicy = this.accommodation.cancellationPolicy;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["accommodation", "campus"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:financial-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
