<template>
  <div>
    <v-row no-gutters>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("LODGING.TITLE18") }}
          </strong>
        </h5>
      </b-col>

      <v-col
        cols="12"
        md="3"
        v-for="(pet, index) in optionsPet"
        :key="index"
        class="custom-checkbox"
      >
        <v-checkbox
          v-model="info.accommodation_pets"
          :label="pet.text"
          :value="pet.value"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row v-if="otherContain(info.accommodation_pets)">
      <!-- Other -->
      <v-col cols="12" md="4">
        <v-text-field
          v-model="info.other_pet"
          :label="$t('LODGING.OTHER')"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row style="margin-top:-20px">
      <!-- Other -->
      <v-col cols="12" md="4">
        <v-checkbox
          v-model="info.allowed_pets"
          :label="$t('LODGING.ALLOWED_PET')"
        />
      </v-col>
    </v-row>

    <v-row v-if="info.allowed_pets" style="margin-top:-30px" no-gutters>
      <v-col cols="12" md="3" class="group-fields-bottom">
        <v-checkbox
          v-model="info.allowed_pets_all"
          :label="$t('LODGING.PETS.PET_ALL')"
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        v-for="(pet, index) in optionsPet"
        :key="index"
        class="custom-checkbox"
        :class="{ 'd-none': info.allowed_pets_all }"
      >
        <v-checkbox
          v-model="info.list_allowed_pets"
          :label="pet.text"
          :value="pet.value"
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { listPet, listPet2 } from "./../util/list";

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        accommodation_pets: [],
        other_pet: null,
        allowed_pets: false,
        list_allowed_pets: [],
        allowed_pets_all: false,
      },
      optionsPet: listPet,
      optionsPet2: listPet2,
    };
  },
  methods: {
    /**
     * set accommodation_persons with new data
     * @method otherContain
     * @returns Boolean
     */
    otherContain(value) {
      if (value) {
        if (value.includes(9)) {
          return true;
        }
      }
      return false;
    },
    fetchData() {
      if (this.edit) {
        this.form.accommodation_pets = [];
        this.form.list_allowed_pets = [];
        this.form.other_pet = this.accommodation.other_pet;
        this.form.allowed_pets = this.accommodation.allowed_pets;
        this.form.allowed_pets_all = this.accommodation.allowed_pets_all;
        const { accommodation_pets, list_allowed_pets } = this.accommodation;
        if (list_allowed_pets) {
          const auxListPet = list_allowed_pets.split(",");
          auxListPet.forEach(pet => {
            this.form.list_allowed_pets.push(Number(pet));
          });
        }
        if (accommodation_pets !== null) {
          const auxPet = accommodation_pets.split(",");
          auxPet.forEach(pet => {
            this.form.accommodation_pets.push(Number(pet));
          });
        }
      }
    },
  },

  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["accommodation"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:pet-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
