<template>
  <div>
    <v-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("LODGING.TITLE14") }}
          </strong>
        </h5>
      </b-col>

      <!-- FAMILY DESCRIPTION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="info.otherInfo"
          :label="$t('LODGING.FAMILY_DESCRIPTION')"
          :rules="[validations.maxValue(info.accommodation_description, 200)]"
          counter="200"
          outlined
          dense
        />
      </b-col>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("LODGING.TITLE15") }}
          </strong>
        </h5>
      </b-col>
      <!-- ------ -->
      <b-col lg="12" md="12" sm="12" cols="12" v-if="isShowPeopleLiving">
        <div
          class="card-custom-accommodation"
          v-for="(people, index) in info.accommodation_persons"
          :key="index"
        >
          <v-row gutters="2" class="mb-0 pb-0">
            <!-- DELETE PEOPLE -->
            <v-col cols="10" md="10">
              <strong>
                {{ $t("LODGING.PERSON_LABEL") }} #{{ index + 1 }}
              </strong>
            </v-col>
            <v-col cols="2" md="2" class="justify-content-end">
              <a
                v-if="index > 0 || company.companyTypeId === 1"
                class="btn btn-icon btn-light btn-hover-danger float-right"
                @click="removePeople(index)"
              >
                <span class="svg-icon svg-icon-md svg-icon-danger">
                  <v-icon color="red">
                    mdi-delete
                  </v-icon>
                </span>
              </a>
            </v-col>

            <!-- Name -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="people.name"
                :label="$t('LODGING.NAME')"
                :rules="validations.required"
                outlined
                dense
              />
            </v-col>

            <!-- Type -->
            <v-col cols="12" md="4">
              <v-select
                v-model="people.typeId"
                :items="optionsPersonType"
                :label="$t('LODGING.TYPE')"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
            </v-col>

            <!-- Sex -->
            <v-col cols="12" md="4">
              <v-select
                v-model="people.sex"
                :items="optionsPersonSex"
                :label="$t('LODGING.GENDER2')"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
            </v-col>

            <!-- Birthday -->
            <v-col cols="12" md="4">
              <DatePicker
                class="PersonBirthday"
                :date="people.birthday"
                :name="$t('LODGING.BIRTHDAY')"
                :input.sync="people.birthday"
              />
            </v-col>

            <!-- Occupation -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="people.occupation"
                :label="$t('LODGING.OCCUPATION')"
                outlined
                dense
              />
            </v-col>

            <!-- Nationalities -->
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="people.nationalities"
                :items="Countries"
                :label="$t('LODGING.NATIONALITIES')"
                item-text="nameTranslation"
                item-value="countryId"
                multiple
                deletable-chips
                small-chips
                dense
                outlined
              />
            </v-col>

            <!-- Ancestry -->
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="people.ancestry"
                :items="Countries"
                :label="$t('LODGING.ANCESTRY')"
                item-text="nameTranslation"
                item-value="countryId"
                multiple
                deletable-chips
                small-chips
                dense
                outlined
              />
            </v-col>

            <!-- Mother tongue -->
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="people.motherTongue"
                :items="languagesMother"
                :label="$t('LODGING.MOTHER_TONGUE')"
                item-text="name"
                item-value="languageId"
                multiple
                deletable-chips
                small-chips
                dense
                outlined
              />
            </v-col>

            <!-- Other mother tongue -->
            <v-col cols="12" md="4" v-if="otherContain(people.motherTongue)">
              <v-text-field
                v-model="people.otherMotherTongue"
                :label="$t('LODGING.OTHER_TONGUE')"
                outlined
                dense
              />
            </v-col>

            <!-- Religion -->
            <v-col cols="12" md="4">
              <v-select
                v-model="people.religion"
                :items="optionsPersonReligion"
                :label="$t('LODGING.RELIGION')"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
            </v-col>

            <!-- Other religion -->
            <v-col cols="12" md="4" v-if="people.religion === 10">
              <v-text-field
                v-model="people.otherReligion"
                :label="$t('LODGING.RELIGION_OTHER')"
                outlined
                dense
              />
            </v-col>

            <!-- Profile -->
            <v-col cols="12" md="12" class="p-0">
              <Profile
                :ref="`imageProfile_${index}`"
                :id_photo="`_${index}`"
                :button_text="$t('GENERAL.UPLOAD_PHOTO')"
                entity="lodging"
                :photo-info.sync="people.photo"
                :required_file="true"
              />
            </v-col>
          </v-row>
        </div>

        <!-- ADD -->
        <v-btn depressed color="primary" class="mb-5" @click="addPeople">
          {{ $t("GENERAL.ADD") }}
        </v-btn>
      </b-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import {
  listPersonType,
  listPersonSex,
  listPersonReligion,
} from "./../util/list";
import Profile from "@/view/content/components/images/Profile";
import DatePicker from "@/view/content/components/DatePicker";

export default {
  components: {
    Profile,
    DatePicker,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    isShowPeopleLiving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languagesMother: null,
      form: {
        otherInfo: null,
        accommodation_persons: [
          {
            typeId: null,
            sex: null,
            birthday: null,
            occupation: null,
            nationalities: null,
            ancestry: null,
            motherTongue: null,
            otherMotherTongue: null,
            religion: null,
            otherReligion: null,
            name: null,
            photo: null,
          },
        ],
      },
      // OPTIONS
      optionsPersonType: listPersonType,
      optionsPersonSex: listPersonSex,
      optionsPersonReligion: listPersonReligion,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * set accommodation_persons with new data
     * @method addPeople
     */
    addPeople() {
      this.form.accommodation_persons.push({
        typeId: null,
        name: null,
        sex: null,
        birthday: null,
        occupation: null,
        nationalities: null,
        ancestry: null,
        motherTongue: null,
        otherMotherTongue: null,
        religion: null,
        otherReligion: null,
      });
    },

    /**
     * set accommodation_persons with new data
     * @method removePeople
     */
    removePeople(index) {
      this.form.accommodation_persons.splice(index, 1);
    },

    /**
     * set accommodation_persons with new data
     * @method otherContain
     * @returns Boolean
     */
    otherContain(value) {
      if (value !== null) {
        if (value.includes(0)) {
          return true;
        }
      }
      return false;
    },
    fetchData() {
      this.languagesMother = this.languages;
      this.languagesMother.push({
        name: this.$t("LODGING.OTHER"),
        languageId: 20,
      });
      if (this.edit) {
        const { accommodation_persons } = this.accommodation;
        const { person_photos } = this.accommodation;
        if (accommodation_persons.length > 0) {
          this.form.accommodation_persons = [];
          accommodation_persons.forEach((element, index) => {
            this.form.accommodation_persons.push(element);
            if (person_photos.length > 0) {
              let auxFamilyPhoto = person_photos.filter(
                photo => photo.fileId === element.photoId
              );
              if (
                auxFamilyPhoto &&
                auxFamilyPhoto[0] &&
                this.$refs[`imageProfile_${index}`]
              ) {
                this.$refs[`imageProfile_${index}`][0].setData(
                  auxFamilyPhoto[0]
                );
              }
            }
          });
        } else {
          this.form.accommodation_persons = [
            {
              typeId: null,
              sex: null,
              birthday: null,
              occupation: null,
              nationalities: null,
              ancestry: null,
              motherTongue: null,
              otherMotherTongue: null,
              religion: null,
              otherReligion: null,
              name: null,
              photo: null,
            },
          ];
        }

        this.form.otherInfo = this.accommodation.otherInfo;
      }
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      "accommodation",
      "Countries",
      "languages",
      "campus",
      "company",
    ]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:people-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
<style lang="scss">
.PersonBirthday {
  .v-input {
    margin-top: 0 !important;
  }
}
</style>
