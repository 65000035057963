<template>
  <v-card :elevation="isShowElevation ? 2 : 0">
    <v-card-text>
      <v-row gutters="2" justify="end" v-if="isShowElevation">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row gutters="2" justify="center">
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="listRoomTable"
            :search="search"
          >
            <template v-slot:[`item.name`]="{ item }">
              <router-link
                :to="{
                  path: '/products/room/edit/' + item.productId,
                }"
              >
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | moment(campus.date_format) }}
            </template>
            <template v-slot:[`item.ACTIONS`]="{ item }">
              <v-icon @click="showDialogDelete(item)">delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              deleteRecord(dataRoom.productId);
              isShowDialogDelete = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ForceDelete
      typeProduct="ROOM"
      :validatePromotions="validatePromotions"
      :validatePackages="validatePackages"
      :productId="dataRoom.productId"
      :redirect="false"
      :isShow="isShowDialogForceDelete"
      @close-force-delete="cleanDataForceDelete()"
    ></ForceDelete>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";
export default {
  components: {
    ForceDelete,
  },
  props: {
    isShowElevation: {
      type: Boolean,
      default: false,
    },
    isReveivedData: {
      type: Boolean,
      default: false,
    },
    listRoomData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isShowDialogDelete: false,
      search: "",
      headers: [
        {
          text: this.$t("PRODUCTS_ADDONS.NAME"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: `${this.$t("PRODUCTS_ADDONS.ROOM")} ${this.$t(
            "PRODUCTS_ADDONS.CATEGORY"
          )}`,
          align: "left",
          sortable: true,
          value: "category.codeName",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.QUANTITY"),
          align: "center",
          sortable: true,
          value: "qtyStudent",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.LODGING"),
          align: "left",
          sortable: true,
          value: "lodging.name",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.LAST_UPDATED"),
          align: "left",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.ACTIONS"),
          align: "center",
          sortable: false,
          value: "ACTIONS",
        },
      ],
      dataRoom: {},
      validatePromotions: [],
      validatePackages: [],
    };
  },
  methods: {
    ...mapActions(["getRooms", "deleteRoom"]),
    showDialogDelete(data) {
      this.dataRoom = data;
      this.isShowDialogDelete = true;
    },
    deleteRecord(productId) {
      this.deleteRoom(productId).then(data => {
        if (data.success) {
          this.validatePromotions = [];
          this.validatePackages = [];
          this.getRooms();
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        } else {
          this.validatePromotions = data.promotion;
          this.validatePackages = data.package;

          this.isShowDialogForceDelete = true;
        }
      });
    },
    cleanDataForceDelete() {
      this.validatePromotions = [];
      this.validatePackages = [];
    },
  },
  computed: {
    ...mapGetters(["rooms", "campus"]),
    listRoomTable() {
      if (this.isReveivedData) {
        return this.listRoomData;
      } else {
        return this.rooms;
      }
    },
  },
};
</script>
