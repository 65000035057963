<template>
  <b-col lg="12" md="12" sm="12" cols="12">
    <v-file-input
      v-model="file"
      accept="image/png, image/jpeg, image/bmp"
      :id="`photo_profile${id_photo}`"
      class="d-none"
      :rules="validations.required"
      @change="uploadPhoto"
    />
    <b-row>
      <b-col lg="4" md="4" sm="12" cols="12">
        <v-card color="white">
          <div
            class="d-flex flex-no-wrap justify-space-between"
            :class="{ 'border--red': valFile }"
          >
            <v-avatar class="ma-3" size="100" tile>
              <img :src="picture" v-if="!isLoader" />
              <b-spinner variant="primary" label="Spinning" v-if="isLoader" />
            </v-avatar>
            <div class="w-100">
              <v-btn
                depressed
                color="primary"
                class="mt-3 d-block"
                @click="photoClick"
              >
                {{ button_text }}
              </v-btn>
              <span class="d-block">
                {{ $t("GENERAL.ACCEPTED_FORMATS") }}
              </span>
              <span class="d-block mt-1 mb-1">
                {{ $t("GENERAL.FORMATS") }}
              </span>
              <span
                class="d-block text-primary btn-picture cursor-pointer"
                @click="deletePicture"
              >
                <font-awesome-icon icon="trash-alt" />
                {{ $t("GENERAL.REMOVE_PHOTO") }}
              </span>
            </div>
          </div>
        </v-card>
        <div v-if="valFile" class="ml-3 text-danger">
          {{ $t("GENERAL.FIELD_ERROR") }}
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  props: {
    button_text: String,
    profile: {
      type: Boolean,
      default: true,
    },
    entity: {
      type: String,
      default: "user",
    },
    id_photo: {
      type: String,
      default: "",
    },
    required_file: {
      type: Boolean,
      default: false,
    },
    get_data_campus_page: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      file: null,
      dataFile: null,
      photo: null,
      noImage: false,
      valFile: false,
      keyImage: 0,
      // VALIDATIONS
      validations: {
        required: [
          v => {
            this.valFile = false;
            if (v === null && this.required_file && this.photo === null) {
              this.valFile = true;
              return this.$t("GENERAL.FIELD_ERROR");
            }
            return true;
          },
        ],
      },
    };
  },
  mounted() {
    this.valFile = false;
    if (this.get_data_campus_page) {
      this.photo = this.campusPage.photo;
    }
  },
  methods: {
    /**
     * Get data
     * @method getData
     * @return dataFile with photo data
     */
    getData() {
      this.dataFile = {
        ...this.dataFile,
        noImage: this.noImage,
      };
      return this.dataFile;
    },

    /**
     * set data
     * @method setData
     */
    setData(data) {
      this.photo = data;
    },

    /**
     * Delete item of the picture
     * @method deletePicture
     */
    deletePicture() {
      this.file = null;
      this.photo = null;
      this.noImage = true;
    },

    /**
     * Upload item of the picture
     * @method uploadPhoto
     * @return 'photo' with new data
     */
    uploadPhoto(e) {
      if (e !== undefined) {
        this.loader = true;
        const { campusId } = this.campus;

        let formData = new FormData();
        formData.append("files", this.file, this.file.name);
        formData.append("folder", "images");
        formData.append("entity", `${this.entity}`);
        campusId && formData.append("campusId", campusId);

        ApiService.setHeader();
        ApiService.postFile("api/v1/file", formData)
          .then(({ data }) => {
            this.dataFile = data.file;
            this.photo = data.file;
            this.loader = false;
          })
          .catch(() => {
            this.photo = null;
            this.loader = false;
          });
      }
    },

    /**
     * get new items
     * @method photoGalleryClick
     */
    photoClick() {
      const input = document.getElementById(`photo_profile${this.id_photo}`);
      input.click();
    },
  },
  computed: {
    ...mapGetters(["campus", "campusPage"]),
    picture() {
      const auxImage = this.profile ? "blank" : "company_default";
      const defaultImage = `${process.env.VUE_APP_CDN_PATH}images/default/${auxImage}.png`;
      return this.photo
        ? `${this.photo.url}?h=100&w=100&fit=crop`
        : defaultImage;
    },
    isLoader() {
      return this.loader;
    },
  },
  watch: {
    photo: {
      handler: function() {
        this.$emit("update:photo-info", this.photo);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.border--red {
  border: 1px solid red;
}
</style>
