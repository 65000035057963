<template>
  <div>
    <b-row>
      <v-file-input
        v-model="fileGallery"
        accept="image/png, image/jpeg, image/bmp"
        class="d-none"
        :id="`component_gallery${id}`"
        multiple
        @change="uploadGalleryPhoto"
      />

      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ title }}
          </strong>

          <!-- INFO -->
          <v-tooltip right v-if="showInfo">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                v-bind="attrs"
                v-on="on"
                class="tooltip-info"
              >
                <font-awesome-icon icon="info-circle" />
              </v-btn>
            </template>
            <span>
              {{ infoText1 }}
              <br />
              {{ infoText2 }}
            </span>
          </v-tooltip>
        </h5>
        <h6>
          <i>
            {{ $t("GENERAL.ACCEPTED_FORMATS") }}
            {{ $t("GENERAL.FORMATS") }}
          </i>
        </h6>
      </b-col>

      <b-col
        lg="12"
        md="12"
        sm="12"
        cols="12"
        class="mb-5"
        v-if="numFiles < 12"
      >
        <v-btn
          v-if="!isOnlyShow"
          depressed
          color="primary"
          :loading="save_loading"
          class="d-block"
          @click="photoGalleryClick"
        >
          {{ $t("GENERAL.UPLOAD") }}
        </v-btn>
      </b-col>
      <div :key="keyGallery">
        <div
          v-for="(item, i) in infoGallery"
          :key="i"
          :class="`container-gallery ${item.highlight ? 'item-highlight' : ''}`"
        >
          <v-tooltip bottom v-if="!isOnlyShow">
            <template v-slot:activator="{ on, attrs }">
              <span class="btn-highlight-gallery" v-bind="attrs" v-on="on">
                <font-awesome-icon
                  icon="star"
                  @click="highlightGalleryPicture(i)"
                />
              </span>
            </template>
            <span>{{ $t("GENERAL.HIGHLIGHT_IMAGE") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!isOnlyShow">
            <template v-slot:activator="{ on, attrs }">
              <span class="btn-delete-gallery" v-bind="attrs" v-on="on">
                <font-awesome-icon
                  icon="trash-alt"
                  @click="deleteGalleryPicture(i)"
                />
              </span>
            </template>
            <span>{{ $t("GENERAL.DELETE") }}</span>
          </v-tooltip>
          <v-card
            color="white"
            class="custom-vcard-gallery"
            @click="onClick(i)"
          >
            <v-avatar size="100" tile>
              <img :src="item.url + '?w=100&h=100&fit=crop'" />
            </v-avatar>
          </v-card>
        </div>
      </div>

      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="images"
        :index="index"
        @hide="handleHide"
      />
    </b-row>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  props: {
    files: Array,
    id: String,
    title: String,
    showInfo: {
      type: Boolean,
      default: false,
    },
    infoText1: {
      type: String,
      default: null,
    },
    infoText2: {
      type: String,
      default: null,
    },
    entity: {
      type: String,
      default: "other",
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileGallery: null,
      gallery: [],
      save_loading: false,
      images: [],
      index: 0,
      visible: false,
      keyGallery: 0,
      hightlihgtId: null,
      TYPE_HIGHLIGHT_IMAGE_GALLERY: 3,
    };
  },
  methods: {
    ...mapActions(["highlightImage"]),
    onClick(i) {
      this.index = i;
      this.visible = true;
    },

    handleHide() {
      this.visible = false;
    },

    /**
     * Update files of the gallery
     * @method updatedGallery
     * @param newFiles array with new images
     */
    updatedGallery(newFiles) {
      this.gallery = newFiles;
      this.fileGallery = null;
    },

    /**
     * Delete item of the gallery
     * @method deleteGalleryPicture
     * @param index index of element
     */
    deleteGalleryPicture(index) {
      this.gallery.splice(index, 1);
      this.images.splice(index, 1);
    },

    /**
     * Highlight item of the gallery
     * @method highlightGalleryPicture
     * @param index index of element
     */
    highlightGalleryPicture(index) {
      let indexHighlited = null;
      let arrIdsGallery = [];
      for (let position = 0; position < this.gallery.length; position++) {
        if (this.gallery[position].highlight) {
          indexHighlited = position;
        }
        this.gallery[position].highlight = false;
        arrIdsGallery.push(this.gallery[position].fileId);
      }
      let sendData;
      if (indexHighlited !== index) {
        this.gallery[index].highlight = true;
        this.hightlihgtId = this.gallery[index].fileId;
        sendData = {
          actual: arrIdsGallery,
          new: this.gallery[index].fileId,
        };
      } else {
        sendData = {
          actual: arrIdsGallery,
          new: null,
        };
      }
      this.highlightImage(sendData).then(() => {
        this.keyGallery += 1;
        if (indexHighlited !== index) {
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        }
      });
    },

    /**
     * Upload item of the gallery
     * @method deleteGalleryPicture
     * @return 'gallery' with new data
     */
    async uploadGalleryPhoto(e) {
      if (e !== undefined) {
        this.save_loading = true;
        const aux = e.length;
        let contador = 1;
        await e.forEach(element => {
          let formData = new FormData();
          if (this.numFiles < 12) {
            formData.append("files", element, element.name);
            formData.append("folder", "images");
            formData.append("entity", this.entity);
            if (this.campus.campusId)
              formData.append("campusId", this.campus.campusId);
            ApiService.setHeader();
            ApiService.postFile("api/v1/file", formData)
              .then(res => {
                const { file } = res.data;
                this.gallery.push(file);
                this.images.push(file.url);
                if (contador === aux) {
                  this.save_loading = false;
                }
                contador++;
              })
              .catch(() => {
                this.save_loading = false;
              });
          }
        });
      }
    },

    /**
     * get new items
     * @method photoGalleryClick
     */
    photoGalleryClick() {
      const input = document.getElementById(`component_gallery${this.id}`);
      input.click();
    },
    fetchData() {
      this.gallery = Vue.util.extend([], this.files);
      this.images = this.gallery?.map(image => image.url);
      this.$emit("data", this.gallery);
    },
  },
  mounted() {
    this.fetchData();
  },
  created() {
    if (this.id === undefined) {
      this.id = "";
    }
  },
  computed: {
    ...mapGetters(["campus"]),
    infoGallery() {
      return this.gallery?.map(item => {
        item.highlight = item.typeId === this.TYPE_HIGHLIGHT_IMAGE_GALLERY;
        return item;
      });
    },
    numFiles() {
      if (this.gallery.length) {
        return this.gallery.length;
      }
      return 0;
    },
  },
  watch: {
    $route: "fetchData",
    files: "fetchData",
  },
};
</script>

<style scoped>
.custom-vcard-gallery {
  width: 125px !important;
  display: inline-block;
  padding: 10px;
  text-align: center;
}

.container-gallery {
  width: 125px !important;
  display: inline-block;
  margin: 5px;
  position: relative;
}

.container-gallery:hover .btn-delete-gallery,
.container-gallery:hover .btn-highlight-gallery {
  display: block !important;
}

.btn-delete-gallery {
  position: absolute;
  right: 15px;
  top: 15px;
  color: red;
  cursor: pointer;
  display: none;
  z-index: 15;
}

.btn-highlight-gallery {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #5867dd;
  cursor: pointer;
  display: none;
  z-index: 15;
  background: rgba(255, 255, 255, 0.2);
}

.item-highlight {
  -webkit-box-shadow: 0px 0px 3px 1px rgba(88, 103, 221, 1);
  -moz-box-shadow: 0px 0px 3px 1px rgba(88, 103, 221, 1);
  box-shadow: 0px 0px 3px 1px rgba(88, 103, 221, 1);
}
</style>
