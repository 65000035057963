<template>
  <div>
    <v-row>
      <!-- TITLE 1 -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("LODGING.TITLE8") }}
          </strong>
          <br />
          <i>
            {{ $t("LODGING.SUB_TITLE") }}
          </i>
        </h5>
      </b-col>
    </v-row>

    <!-- SMOKE ALLOWED -->
    <v-row no-gutters>
      <b-col lg="4" md="4" sm="4" cols="12">
        <v-checkbox v-model="info.smokeAllowed" :label="$t('LODGING.SMOKE')" />
      </b-col>
    </v-row>

    <!-- FACILITIES -->
    <v-row no-gutters>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          {{ $t("LODGING.TITLE10") }}
        </h5>
      </b-col>
      <v-col
        cols="12"
        md="4"
        v-for="(amenity, index) in amenityOne"
        :key="index"
        class="custom-checkbox"
      >
        <v-checkbox
          v-model="info.facilities_facilities"
          :label="amenity.name"
          :value="amenity.amenityTypeId"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row gutters="2" class="pt-2 pb-0 mb-0">
      <!-- LAUNDRY SERVICE -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.laundryService"
          :label="$t('LODGING.LAUNDRY')"
          class="mt-2"
        />
      </b-col>

      <!-- QUANTITY -->
      <b-col lg="4" md="4" sm="4" cols="12" v-if="info.laundryService">
        <v-text-field
          v-model="info.laundryServiceQty"
          type="number"
          :label="$t('LODGING.FRECUENCY')"
          :rules="validations.integer"
          hide-details="auto"
          outlined
          dense
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              {{ $t("LODGING.HELP_LAUNDRY_SERVICE") }}
            </v-tooltip>
          </template>
        </v-text-field>
      </b-col>

      <!-- DURATION TYPE -->
      <b-col lg="4" md="4" sm="4" cols="12" v-if="info.laundryService">
        <v-select
          v-model="info.laundryServiceDurationTypeId"
          :items="optionsDuration"
          :label="$t('LODGING.EVERY')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
    </v-row>

    <v-row gutters="2" class="pt-2 pb-0 mb-0">
      <v-col cols="12" md="3">
        <v-checkbox
          label="Cleaning service"
          v-model="info.cleaningService"
          class="mt-2"
        ></v-checkbox>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" md="4" v-if="info.cleaningService">
          <v-text-field
            v-model="info.cleaningServiceQuantity"
            :rules="validations.requiredNoNegative"
            type="number"
            :label="$t('LODGING.QUANTITY')"
            dense
            outlined
            hide-details="auto"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                {{ $t("LODGING.HELP_CLEANING_SERVICE") }}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" md="4" v-if="form.cleaningService">
          <v-select
            :rules="validations.required"
            v-model="info.cleaningServiceDurationType"
            :label="$t('LODGING.DURATION_TYPE')"
            :items="typeDuration"
            item-value="id"
            dense
            outlined
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-expand-transition>
    </v-row>

    <v-row gutters="2" class="pt-0 pb-2 mb-0">
      <v-col cols="12" md="3">
        <v-checkbox
          label="Bed linen change"
          v-model="info.changeBedLinen"
          class="mt-2"
        ></v-checkbox>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" md="4" v-if="info.changeBedLinen">
          <v-text-field
            v-model="info.bedLinenChangeQuantity"
            :label="$t('LODGING.QUANTITY')"
            :rules="validations.requiredNoNegative"
            type="number"
            dense
            outlined
            hide-details="auto"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                {{ $t("LODGING.HELP_BED_LINEN") }}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" md="4" v-if="info.changeBedLinen">
          <v-select
            v-model="info.bedLinenChangeDurationType"
            :label="$t('LODGING.DURATION_TYPE')"
            :items="typeDuration"
            item-value="id"
            dense
            outlined
            :rules="validations.required"
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-expand-transition>
    </v-row>

    <!-- FACILITIES TYPE 3 -->
    <v-row no-gutters>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          {{ $t("LODGING.TITLE11") }}
        </h5>
      </b-col>
      <v-col
        cols="12"
        md="3"
        v-for="(amenity, index) in amenityThree"
        :key="index"
        class="group-fields-bottom"
      >
        <v-checkbox
          v-model="info.facilities_security"
          :label="amenity.name"
          :value="amenity.amenityTypeId"
          hide-details="auto"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import { optionsDuration } from "./../util/list";

const INSURANCE = 3;
const HOMESTAY = 7;
const GENERAL_AMENITY = 2;
const CATEGORY_HOMESTAY = 1;

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        smokeAllowed: false,
        facilities_facilities: null,
        laundryService: false,
        laundryServiceQty: null,
        laundryServiceDurationTypeId: null,
        facilities_security: null,
        cleaningService: null,
        cleaningServiceDurationType: null,
        cleaningServiceQuantity: null,
        changeBedLinen: null,
        bedLinenChangeDurationType: null,
        bedLinenChangeQuantity: null,
      },
      auxAmenity: null,
      // OPTIONS
      optionsDuration: optionsDuration,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    const { accommodationTypeId } = this.accommodation;
    this.updatedAmenity(accommodationTypeId);
  },
  methods: {
    updatedAmenity(type) {
      this.info.facilities_facilities = [];
      const { accommodationTypeId, facilities_facilities } = this.accommodation;
      if (accommodationTypeId === type) {
        this.form.facilities_facilities = facilities_facilities;
      }

      this.auxAmenity = this.amenityTypes.filter(amenity => {
        if (type === CATEGORY_HOMESTAY) {
          return amenity.typeId === HOMESTAY;
        } else {
          return amenity.typeId === GENERAL_AMENITY;
        }
      });
    },
    fetchData() {
      if (this.edit) {
        this.form.smokeAllowed = this.accommodation.smokeAllowed;
        this.form.facilities_facilities = this.accommodation.facilities_facilities;
        this.form.laundryService = this.accommodation.laundryService;
        this.form.laundryServiceQty = this.accommodation.laundryServiceQty;
        this.form.laundryServiceDurationTypeId = this.accommodation.laundryServiceDurationTypeId;
        this.form.facilities_security = this.accommodation.facilities_security;
        this.form.cleaningServiceDurationType = this.accommodation.cleaningServiceDurationType;
        this.form.cleaningServiceQuantity = this.accommodation.cleaningServiceQuantity;
        this.form.bedLinenChangeDurationType = this.accommodation.bedLinenChangeDurationType;
        this.form.bedLinenChangeQuantity = this.accommodation.bedLinenChangeQuantity;
        this.form.cleaningService =
          this.accommodation.cleaningServiceQuantity !== null ? true : false;
        this.form.changeBedLinen =
          this.accommodation.bedLinenChangeQuantity !== null ? true : false;
      }
    },
  },
  computed: {
    ...mapGetters(["accommodation", "amenityTypes"]),
    info() {
      return this.form;
    },
    amenityOne() {
      return this.auxAmenity;
    },
    amenityThree() {
      const data = this.amenityTypes.filter(amenity => {
        return amenity.typeId === INSURANCE;
      });
      return data;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:lodging-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
