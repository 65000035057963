<template>
  <div>
    <b-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("CAMPUS.TITLE4") }}
          </strong>
        </h5>
      </b-col>

      <!-- CITY_DESCRIPTION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="info.accommodation_description"
          :label="$t('LODGING.ACCOMMODATION_DESCRIPTION')"
          dense
          outlined
          :rules="[validations.biggerNumber(info.enviromentDescription, 3000)]"
          counter="3000"
        />
      </b-col>

      <!-- SCHOOL_DESCRIPTION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="info.enviroment_description"
          :label="$t('LODGING.ENVIROMENT_DESCRIPTION')"
          dense
          outlined
          :rules="[validations.maxValue(info.accommodation_description, 3000)]"
          counter="3000"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        accommodation_description: null,
        enviroment_description: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.edit) {
        this.form.accommodation_description = this.accommodation.accommodation_description;
        this.form.enviroment_description = this.accommodation.enviroment_description;
      }
    },
  },
  computed: {
    ...mapGetters(["accommodation"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:description-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
